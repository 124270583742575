<template>
  <!-- 页面名称：我的课程-视频
      开发人员：zqq -->
  <div class="video" v-loading="loading">
    <div class="course-video">
      <div class="course-video-title">
        <div class="null-box" @click="upPage()">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="course-video-name">
          <span>{{ courseActive.title }}</span>
        </div>

      </div>
      <div class="quarter">
        <span class="quart-section">{{ videoActive.sec_title }}</span>
        <span class="period">课程时长：{{ videoTime }}</span>
      </div>
    </div>
    <div class="course-video-content">
      <div class="course-video-wrap" id="course-video-wrap">
        <video-player
                class="vjs-custom-skin"
                v-if="chapList.length !== 0"
                ref="videoPlayer"
                :playsinline="true"
                :options="playerOptions"
                @play="onPlayerPlay($event)"
                @pause="onPlayerPause($event)"
                @ended="onPlayerEnded($event)"
                @timeupdate="onPlayerTimeupdate($event)"
        >
        </video-player>
      </div>
      <div class="course-video-details">
        <div class="video-details-warp">
          <div class="video-title-teacher" style="display: none">
            <img src="../../../assets/img/teacher.png" alt="头像" />
          </div>
          <div class="video-title-name">
            <div class="video-title-name-big">{{ crs_name }}</div>
            <div class="video-title-name-small">{{ instru_name }}</div>
          </div>
        </div>
        <div class="video-details-list" id="video-details-list" :style="{height: pageWidth > 1025 ? videoHeight - 102 + 'px' : 'auto'}">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
                    v-for="(item, index) in chapList"
                    :key="index"
                    :name="index.toString()"
            >
              <template slot="title">
                <div class="article-name" @click="changeChapter(index)">
                  <span
                          class="article-num"
                          v-html="courseTitleValue(index)"
                  ></span>
                  <span>{{ item.title }}</span>
                </div>
              </template>
              <div
                      class="list-item"
                      v-for="(val, subscript) in item.sec_list"
                      :key="subscript"
                      @click="videoList(index, subscript)"
                      :class="{
                  'change-list': item.sec_list[subscript] === videoActive,
                }"
              >
                <div class="list-item-title">
                  <div
                          class="play-animation"
                          v-show="
                      (openArray[0] === index ||
                        openArray[0].toString() === index) &&
                      openArray[1] === subscript
                    "
                  >
                    <div class="animation-one animation"></div>
                    <div class="animation-two animation"></div>
                    <div class="animation-three animation"></div>
                  </div>
                  {{ val.sec_title }}
                </div>
                <div class="course-progress" v-if="val.rate">
                  <p class="rate">{{ val.rate }}%</p>
                  <div class="schedule">
                    <div
                            class="progress"
                            :style="{ left: proportion(val.rate) }"
                    ></div>
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <!-- 空白 -->
    <div style="width: 100%;height: 50px"></div>
  </div>
</template>
<script>
  import {
    getVideo,
    postStudyVideo,
    postStudyRecord,
  } from "../../../http/api/Course";
  import { videoPlayer } from "vue-video-player";
  import "video.js/dist/video-js.css";
  import "videojs-contrib-hls";
  import { mapState } from 'vuex';
  export default {
    components: {
      videoPlayer,
    },
    data() {
      return {
        loading: true, //加载
        videoHeight: 202,
        crs_id: "",
        chapList: [], //
        openArray: [0, 0], // 播放记录 第一个是章 第二个是节
        videoActive: [], //节
        courseActive: [], //章
        titleValue: "", //下标转换成01
        activeName: "0", //默认展开上次播放章
        currentTime: "", //视频默认时长
        videoTime: "",
        videoTimer: 0, //视频时间
        totalTime: 0, //视频总时长
        recordTimer: "", //学习进度定时器
        video_timer: "", //视频进度定时器
        crs_name: "", //课程名字
        instru_name: "", //主讲老师名字
        playerOptions: {
          playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
          autoplay: false, // 如果为true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 是否视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "", // 类型
              src: "", // url地址
            },
          ],
          poster: "", // 封面地址
          notSupportedMessage: "此视频正在加载中，请耐心等待", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: false, // 当前时间和持续时间的分隔符
            durationDisplay: false, // 显示持续时间
            remainingTimeDisplay: true, // 是否显示剩余时间功能
            fullscreenToggle: true, // 是否显示全屏按钮
          },
        },
      };
    },
    methods: {
      video() {
        this.crs_id = this.$route.query.id;
        this.crs_name = this.$route.query.crs_name;
        this.instru_name = this.$route.query.instru_name;
        getVideo({
          crs_id: this.crs_id,
        }).then((res) => {
          // console.log(res);
          if (res.code == 200) {
            this.chapList = res.data.chap_list;
            this.openArray = res.data.open_array;
            this.currentTime = res.data.initial_time;
            this.activeName = res.data.open_array[0].toString();
            this.courseActive = this.chapList[this.openArray[0]];
            this.videoActive =
                    this.chapList[this.openArray[0]].sec_list[this.openArray[1]];
            var minute = this.videoActive.video_time;
            this.videoTime =
                    Math.floor(minute / 60) + "小时" + (minute % 60) + "分"; //计算这个视频一共多长时间
            this.playerOptions.sources[0].src =
                    this.chapList[this.openArray[0]].sec_list[
                            this.openArray[1]
                            ].sec_video;
          }
          this.loading = false;
        });
      },
      //进度
      proportion(val) {
        //val   0-100   30
        var data = 100 - val;
        var proportion = "-" + data + "%";
        return proportion;
      },
      //渲染时自动调用1转换01
      courseTitleValue(index) {
        index++;
        return (this.titleValue = index >= 10 ? index : "0" + index);
      },
      //保存视频进度
      studyVideo(timer) {
        let chap_id, sec_id;
        this.chapList.forEach((item, index) => {
          if (this.openArray[0] === index) {
            chap_id = item.id;
            item.sec_list.forEach((val, i) => {
              if (this.openArray[1] === i) {
                sec_id = val.sec_id;
              }
            });
          }
        });
        postStudyVideo({
          crs_id: this.crs_id,
          chap_id: chap_id,
          sec_id: sec_id,
          play_time: timer,
        }).then((res) => {
          console.log(res);
        });
      },
      //保存学习进度
      studyRecord(timer, state) {
        let chap_id, sec_id;
        this.chapList.forEach((item, index) => {
          if (this.openArray[0] === index) {
            chap_id = item.id;
            item.sec_list.forEach((val, i) => {
              if (this.openArray[1] === i) {
                sec_id = val.sec_id;
              }
            });
          }
        });
        postStudyRecord({
          crs_id: this.crs_id,
          chap_id: chap_id,
          sec_id: sec_id,
          current_time: timer,
          total_time: this.totalTime,
        }).then((res) => {
          // console.log(res);
          if (res.code === 200) {
            //判断视频是否播放完， 是就切换到下一章节
            if (state === "over") {
              this.videoTimer = 0; //视频播放时间
              let atrr = this.courseActive.sec_list;
              let item = this.videoActive;
              let hh = atrr.indexOf(item); //当前节的位置
              let listLength = this.courseActive.sec_list.length - 1; //当前章的节的数量
              //判断当前节是不是在所在章的最后一节  不是就切换到下一节 是就切换到下一章
              if (hh < listLength) {
                this.openArray[1] = hh + 1;
                this.videoActive =
                        this.chapList[this.openArray[0]].sec_list[this.openArray[1]];
                this.playerOptions.sources[0].src =
                        this.chapList[this.openArray[0]].sec_list[
                                this.openArray[1]
                                ].sec_video;
                let minute = this.videoActive.video_time;
                this.videoTime =
                        Math.floor(minute / 60) + "小时" + (minute % 60) + "分";
                // console.log(this.openArray);
              } else {
                let chapLists = this.chapList;
                let coruse = this.courseActive;
                let nn = chapLists.indexOf(coruse);
                let length = this.chapList.length - 1;
                //判断当前章是不是在最后一章，不是就到下一章的第一节，是就切换到第一章
                if (nn < length) {
                  this.openArray[0] = nn + 1;
                  this.openArray[1] = 0;
                  this.activeName = this.openArray[0].toString(); //切换默认展开第几章节
                  this.courseActive = this.chapList[this.openArray[0]];
                  this.videoActive =
                          this.chapList[this.openArray[0]].sec_list[this.openArray[1]];
                  this.playerOptions.sources[0].src =
                          this.chapList[this.openArray[0]].sec_list[
                                  this.openArray[1]
                                  ].sec_video;
                  let minute = this.videoActive.video_time;
                  this.videoTime =
                          Math.floor(minute / 60) + "小时" + (minute % 60) + "分";
                  // console.log(this.openArray);
                } else {
                  this.openArray[0] = 0;
                  this.openArray[1] = 0;
                  this.activeName = this.openArray[0].toString();
                  this.courseActive = this.chapList[this.openArray[0]];
                  this.videoActive =
                          this.chapList[this.openArray[0]].sec_list[this.openArray[1]];
                  this.playerOptions.sources[0].src =
                          this.chapList[this.openArray[0]].sec_list[
                                  this.openArray[1]
                                  ].sec_video;
                  let minute = this.videoActive.video_time;
                  this.videoTime =
                          Math.floor(minute / 60) + "小时" + (minute % 60) + "分";
                  // console.log(this.openArray);
                }
              }
            }
          } else {
            console.log(res.error);
          }
        });
      },
      //章改变
      changeChapter(index) {
        if (this.openArray[0] === index) {
          return;
        }
      },
      //节改变
      videoList(index, subscript) {
        // console.log(index, subscript);
        if (this.openArray[0] === index && this.openArray[1] === subscript) {
          return;
        }
        this.studyRecord(this.videoTimer);
        this.videoTimer = 0;
        //切换视频清除定时器
        clearInterval(this.video_timer);
        clearInterval(this.recordTimer);
        this.openArray[0] = index;
        this.openArray[1] = subscript;
        // console.log(this.openArray);
        this.courseActive = this.chapList[this.openArray[0]];
        this.videoActive =
                this.chapList[this.openArray[0]].sec_list[this.openArray[1]];
        this.playerOptions.sources[0].src =
                this.chapList[this.openArray[0]].sec_list[this.openArray[1]].sec_video;
        var minute = this.videoActive.video_time;
        this.videoTime = Math.floor(minute / 60) + "小时" + (minute % 60) + "分";
      },
      // 播放回调
      onPlayerPlay(player) {
        this.totalTime = Math.floor(player.cache_.duration);
        if (this.currentTime !== "") {
          player.currentTime(this.currentTime);
          this.currentTime = "";
        }
        if (this.recordTimer === "") {
          this.recordTimer = setInterval(() => {
            this.studyRecord(this.videoTimer); // 保存学习进度
          }, 60000);
          this.video_timer = setInterval(() => {
            this.studyVideo(this.videoTimer); // 保存视频进度
          }, 30000);
        }
      },
      // 暂停回调
      onPlayerPause() {
        clearInterval(this.video_timer);
        clearInterval(this.recordTimer);
        this.recordTimer = "";
        this.video_timer = "";
      },
      // 视频播完回调
      onPlayerEnded() {
        let state = "over";
        this.studyRecord(this.videoTimer, state);
      },
      // 当前播放位置发生变化时触发。
      onPlayerTimeupdate(player) {
        if (Math.floor(player.cache_.currentTime) - this.videoTimer >= 30) {
          this.videoTimer = Math.floor(player.currentTime());
          this.studyVideo(this.videoTimer); //保存视频进度
          this.studyRecord(this.videoTimer); // 保存学习进度
          return;
        }
        this.videoTimer = Math.floor(player.currentTime());
      },
      //返回上一页
      upPage() {
        this.studyRecord(this.videoTimer);
        this.$router.push("/home/course");
        clearInterval(this.video_timer);
        clearInterval(this.recordTimer);
      },
    },
    //路由守卫
    beforeRouteLeave(to, from, next) {
      this.studyRecord(this.videoTimer);
      clearInterval(this.video_timer);
      clearInterval(this.recordTimer);
      next(true);
    },
    mounted() {
      this.video();
      setTimeout(()=>{
        this.videoHeight = this.$refs.videoPlayer.$el.offsetHeight
      }, 500)
    },
    computed: {
      ...mapState(['pageWidth']),
    },
    watch: {
      pageWidth() {
        this.videoHeight = this.$refs.videoPlayer.$el.offsetHeight
      }
    }
  };
</script>

<style lang="scss" scoped>
  .video {
    width: 100%;
    .course-video {
      padding: 21px 0;
      height: 65px;
      width: 100%;
      background-color: #fff;
      .course-video-title {
        margin-left: 45px;
        width: calc(100% - 45px);
        height: 40px;
        line-height: 40px;
        display: flex;
        .null-box {
          padding-right: 10px;
          cursor: pointer;
          margin-top: -7px;
          i{
            font-size: 22px;
            font-weight: 700;
            color: #2c2c2c;
          }
        }
        .course-video-name {
          font-size: 16px;
          font-family: PingFang SC, PingFang SC-Medium;
          font-weight: 700;
          text-align: left;
          color: #181818;
        }
      }
      .quarter {
        height: 25px;
        line-height: 25px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #657489;
        margin-left: 79px;
        padding: 0;
        .quart-section {
          margin-right: 22px;
        }
      }
    }
    .course-video-content{
      display: flex;
      width: 100%;
      min-height: 100px;
      background-color: #fff;
      .course-video-wrap{
        width: 77%;
        min-height: 100px;
        ::v-deep .vjs-custom-skin > .video-js .vjs-big-play-button {
          left: calc(50% - 43px);
          top: calc(50% - 21px);
        }
      }
      .course-video-details{
        width: 23%;
        min-height: 100px;
        background-color: #fff;
        .video-details-warp {
          height: 102px;
          display: flex;
          align-items: center;
          padding-left: 24px;
          box-sizing: border-box;
          background-color: #00264d;
          .video-title-teacher {
            width: 59px;
            margin-right: 11px;
            img {
              width: 100%;
              vertical-align: middle;
            }
          }
          .video-title-name {
            .video-title-name-big {
              margin-bottom: 4px;
              font-size: 18px;
              color: #fff;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
            }

            .video-title-name-small {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #ffffff;
              opacity: 0.6;
            }
          }
        }
        .video-details-list {
          margin-left: 24px;
          padding-right: 18px;
          min-height: 360px;
          background: #ffffff;
          box-sizing: border-box;
          overflow-x: auto;
          .article-name {
            width: 100%;
            display: flex;
            // align-items: center;
            span:nth-child(1) {
              font-size: 14px;
              font-family: DIN, DIN-Bold;
              font-weight: 700;
              color: #657489;
              margin-right: 16px;
              line-height: 26px;
              margin-bottom: 5px;
            }
            span:nth-child(2) {
              width: 285px;
              font-size: 16px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              color: #00264d;
              line-height: 24px;
            }
          }
          .list-item {
            display: flex;
            justify-content: space-between;
            height: 50px;
            align-items: center;
            .list-item-title {
              display: flex;
              margin-left: 36px;
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #00264d;
            }

            .course-progress {
              margin-right: 20px;
              height: 20px;
              display: flex;
              align-items: center;

              .rate {
                font-size: 12px;
                font-family: PingFang SC, PingFang SC-Semibold;
                font-weight: 600;
                color: #0080ff;
              }

              .schedule {
                width: 73px;
                height: 6px;
                border-radius: 3px;
                background: #eff0f5;
                overflow: hidden;
                margin-left: 10px;

                .progress {
                  width: 73px;
                  height: 6px;
                  border-radius: 3px;
                  background: #0080ff;
                  position: relative;
                  left: -100%;
                  transition: 0.5s;
                }
              }
            }

            .video-details-warp {
              height: 102px;
              display: flex;
              align-items: center;
              padding-left: 24px;
              box-sizing: border-box;
              background-color: #00264d;
              .change-list {
                background: #f1f4f9;
                .list-item-title {
                  color: #0080ff;
                }
              }
            }
          }
        }
      }
    }
    ::v-deep .el-collapse-item__header {
      height: 51px;
      line-height: 51px;
      border-bottom: none;
      box-sizing: border-box;
    }

    ::v-deep .el-collapse-item__wrap {
      border-bottom: none;
    }

    ::v-deep .el-collapse-item__content {
      padding-bottom: 0;
    }
    ::v-deep .el-progress__text {
      color: #0080ff;
    }

    /* 音符跳动样式 start */
    .play-animation {
      width: 15px;
      height: 17px;
      margin-right: 10px;
      display: flex;
      align-items: flex-end;

      .animation {
        width: 3px;
        margin-right: 3px;
        background-color: #0080ff;
      }

      .animation:nth-child(1) {
        height: 6px;
        animation: animationOne 0.9s infinite;
      }

      .animation:nth-child(2) {
        height: 17px;
        animation: animationTwo 1s infinite;
      }

      .animation:nth-child(3) {
        height: 11px;
        margin-right: 0;
        animation: animationThree 0.7s infinite;
      }

      @keyframes animationOne {
        0% {
          height: 3px;
        }
        50% {
          height: 17px;
        }
        100% {
          height: 0;
        }
      }
      @keyframes animationTwo {
        0% {
          height: 17px;
        }
        50% {
          height: 0;
        }
        100% {
          height: 17px;
        }
      }
      @keyframes animationThree {
        0% {
          height: 11px;
        }
        50% {
          height: 17px;
        }
        100% {
          height: 0;
        }
      }
    }
    /* 音符跳动样式 end */
  }
  @media screen and (max-width: 1024px) {
    .video{
      .course-video{
        padding-top: 0;
        .course-video-title{
          margin-left: 0;
        }
        .quarter{
          margin-left: 34px;
        }
      }
      .course-video-content{
        display: block;
        .course-video-wrap{
          width: 100%;
        }
        .course-video-details{
          width: 100%;
        }
      }
    }

  }
</style>
